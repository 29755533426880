<template>
  <div class="content" v-if="showpPage">
    <!--<h3>飞科智能产品</h3>-->
    <img src="../assets/logo.png" class="logo">
    <div class="text">为你与众不同</div>
    <div class="name">{{ productName }}</div>
    <div class="btn">
      <a @click="$router.push({path:'/instructions-'+productSn})" class="button" v-if="insBt">产品使用说明</a>
      <!-- <a href="https://www.enchen.cn/service/0-0" class="button" style="margin-top: 0.53rem;">售后服务信息</a> -->
    </div>

    <div class="bottom">
      <span>全国售后服务热线<br><a href="tel:400-0320-070">400-118-1225</a></span>
      <span>https://www.enchen.cn</span>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {

  data() {
    return {
      productName: '',
      params: '',
      id: 1,
      insBt: false,
      productSn: '',
      name: '',
      showpPage: false,
      code:''
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      // http://localhost:8080/?uniqueCode=27981238833604431580293172461428390&code=871&name=FS871&time=1576502706177&sign=8CB7890EC8FF9C130597317E2288C612&version=1
      this.params = this.$route.query
      if(JSON.stringify(this.params) !== '{}') {
        this.productName = this.$route.query.name.split("\\")[0]
        this.code = this.$route.query.code

        if(this.code.includes('988GY')||this.code.includes('985GY')){
          this.productName = this.productName.replaceAll('感应款','');
        }

        // if (this.productName.includes('博锐')) {
        //   return;
        // }
      }
      let _this = this;

      let data = {...this.$route.query, referer: document.referrer}

      request({
        url: '/api/uniqueCode/save',
        method: 'post',
        data
      }).then(function (res) {
        if (res.data.code == 200) {
          _this.productSn = res.data.data.productSn;
          console.log(_this.productSn)
          _this.insBt = res.data.data.isShow
          _this.name = res.data.data.productSn
        }
        _this.showpPage = true;
      }).catch(function (err) {
        _this.showpPage = true;
        // console.log(err.data.message);
      });

    }

  },
  components: {}
};
</script>
<style scoped>
.name {
  margin-top: 30px;
  text-align: center;
  font-size: 0.35rem;
}

.content {
  height: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 0.3rem;
  box-sizing: border-box;
}

.content h3 {
  text-align: center;
  font-size: 0.44rem;
  letter-spacing: 0.03rem;
}

.content .logo {
  margin: 0 auto;
  width: 5.6rem;
  height: 2.75rem;
  display: block;
  margin-top: 3rem;
}

.content .text {
  margin: 0 auto;
  margin-top: 0.5rem;
  font-size: 0.45rem;
  letter-spacing: 0.02rem;
  text-align: center;
}

.content .btn {
  padding-top: 3rem;
}

.content .button {
  margin: 0 auto;
  display: block;
  width: 3.21rem;
  height: 0.91rem;
  line-height: 0.91rem;
  text-decoration: none;
  color: #ababab;
  border: 1px solid #c8c8c8;
  text-align: center;
  border-radius: 0.5rem;
  font-size: 0.38rem;
}

.content .bottom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0.69rem;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  color: #B3B3B3;
  left: 0;
  font-size: 0.3rem;
}

.content .bottom a {
  color: #B3B3B3;
  text-decoration: none;
  margin-top: 0.1rem;
  display: inline-block;
}

.content .hjt {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 10px;
  background: pink;
}
</style>
<style>
* {
  padding: 0;
  margin: 0;
}

body, html {
  height: 100%;
}

#app {
  height: 100%;
}
</style>
